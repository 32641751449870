// Here you can add other styles
@font-face {
   font-family: cubotoo;
   src: url('./HelveticaNowText-RegIta.ttf');
}

.cubotoo-font {
   font-family: 'cubotoo'
}

.cub-card-header {
   /*background-color: #2c384aF2 !important;*/
   background-color: rgb(44 56 74 / 0.85) !important;
   color: '#FFFFFF' !important;
}
.cubotoo-black-text{
   color: #1A171B !important;
}
.cubotoo-yellow-text{
   color: #FFE600 !important;
}
.cubotoo-black-background{
   background-color: #1A171B !important;
}
.cubotoo-yellow-background{
   background-color: #FFE600 !important;
}

.cub-card-header-font{
   color: '#FFFFFF' !important;
}

.cub-card-body{
   min-height:80vh !important;
   /*background-color: #3b05ff2c !important;*/
}

.cub-card-body-one-third{
   width:15vw !important;
}

.cub-card-body-color{
   /*background-color: #ffd1050e !important;*/
   background-color: #F0F4F7 !important;
}

.cub-accordion-body{
   /*background-color: #3b05ff3c !important;*/
   padding-top: 15px !important;
}

.cub-according-header {
   /*background-color: #2c384aF2 !important;*/
   background-color: #2c384aD9 !important;
   color: '#FFFFFF' !important;
   font-size: .3rem  !important;
}

.cub-body-spacer{
   margin-top: 1rem;
   margin-bottom: 1.5rem;
}

.cub-img-height-200{
   height: 200px !important;
}

.cub-img-height-100{
   height: 150 !important;
}

.cub-fullscreen-card{
   min-height: 83vh !important;
}
/*
COREUI OVERRIDES
*/
.form-label{
   font-size: 0.75rem !important;
}

.card-title{
   font-size: 1rem !important;
}